import { fixTwoDecimal } from '@/utils/functions'
import Image from 'next/image'
import Link from 'next/link'
import { memo } from 'react'
import { BsTrash } from 'react-icons/bs'

const CartLine = ({ toggleCartDrawer, hostname, currency, line, handleDelete, loadingLine, globalTexts, index, length }) => {
  const { business, host } = hostname
  const { isNotBot } = hostname
  return !!globalTexts && (
    <div className="hover:bg-neutral-100">
      <div className="flex items-center justify-between" >
        <Link prefetch={isNotBot} href={`${host}/${line.url}`} passHref onClick={() => toggleCartDrawer(false)}>
          <div className="grid grid-cols-3 px-2 items-center rounded-md">
            <div className="w-full p-2">
              <Image
                src={line.image}
                alt={line.alt}
                width={250}
                height={250}
                loading="lazy"
                decoding="async"
                fetchPriority="low"
                className="w-full h-auto"
                unoptimized={true}
              />
            </div>
            <div className="flex flex-col ml-2 col-span-2 w-full">
              <p className="text-base font-bold text-black">{`${fixTwoDecimal((line.total ?? line.total_price) * currency.exchange)} ${currency.symbol}`}</p>
              <p className="uppercase text-xs mt-1 text-neutral-400">{line.brand_name ?? business.alias }</p>
              <p className="line-clamp-1 text-ellipsis text-black">{line.name}</p>
              <span className="flex items-center justify-between">
                <p className="text-sm text-neutral-500">{`${globalTexts.amount}: ${line.amount}`}</p>
                {!!line.talla_translations_av?.length && <p className="mr-1 text-sm text-neutral-500">{`${line.talla_translations_atr}: ${line.talla_translations_av}`}</p>}
              </span>
            </div>
          </div>
        </Link>
        <button onClick={() => handleDelete(line)} disabled={loadingLine} className="text-neutral-500 hover:text-red-500 h-full w-full py-2">
          <BsTrash className="size-6 " />
        </button>
      </div>
    </div>
  )
}

export default memo(CartLine)

'use client'

import { LoadingContext } from '@/context/tools/loading'
import { config } from '@/middleware/config/config'
import IconsSVG from '@/utils/icons/IconsSVG'

import { hasCookie } from 'cookies-next'
import Link from "next/link"
import { useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { IoIosArrowBack, IoIosArrowUp, IoMdClose } from 'react-icons/io'
import { BoxChips } from './components'

const {
  COOKIES: { LAST_SEARCH, COOKIES_POLICY },
} = config

export const TopSearch = props => {
  const {
    text, setText, handleText, handleSynonyms, value, chips, setChips, handleCloseSearchModal, hostname
  } = props
  const { business, colors, mobile } = hostname
  const router = useRouter()
  const pageParams = useSearchParams()
  const { modules } = useContext(LoadingContext)
  const { img, src } = pageParams
  const aceptedCookies = useMemo(() => hasCookie(COOKIES_POLICY), [])

  const handleClear = useCallback(async(itemVal, start, end) => {
    const rep = await text.replace(`${itemVal}`, ``).trim()
    await setChips(x => x.filter(b => rep?.split(` `).includes(itemVal)))
    aceptedCookies && await localStorage.setItem(LAST_SEARCH, rep)
    await setText(x => rep)
  })

  const handleReplace = useCallback((
    oldValue, newValue, start, end
  ) => {
    const rep = text.
      replace(`${!start ? ` ` : ``}${oldValue}${!end ? ` ` : ``}`,
        `${!start ? ` ` : ``}${newValue}${!end ? ` ` : ``}`).
      trim()
    setText(x => rep)
    aceptedCookies && localStorage.setItem(LAST_SEARCH, rep)
  })

  const handleReplaceAll = useCallback(async(oldValue, newValue, flag = false) => {
    if (text === `` || flag) {
      await setChips(x => [
        {
          idx: null,
          label: newValue,
          referer: newValue,
          save: false,
        },
      ])
      await setText(newValue.trim())
    } else {
      await setText(x => x.replace(oldValue.trim(), newValue.trim()))
    }
    const tmp = await text?.split(` `)
    handleSynonyms(tmp)
  })

  const handleEnter = useCallback(e => {
    e.preventDefault()

    if (e.keyCode === 13 && chips?.length > 0) {
      const search = chips.map(x => x.label.toLowerCase()).join(`+`)
      modules.handleLinkLoading(true)
      handleCloseSearchModal()
      router.push(`${globalThis.window.location.origin}/search?s=${search}`)
    }
  })

  useEffect(() => {
    if (value !== ``) {
      const tmp = value.trim()?.split(` `)
      handleSynonyms(tmp, false)
    } else {
      setChips([])
    }
  }, [value])

  return (
    <>
      {mobile && (
        <div className="flex float-start items-center justify-center w-[12dvw] mt-1">
          {mobile ? (
            <button onClick={handleCloseSearchModal} className="text-gray-700">
              <IoIosArrowBack size={24} className="mt-1" />
            </button>
          ) : (
            <Link href="/" passHref>
              <IconsSVG name="logo" path={business?.logo} fillcolor={colors.main} wdth={200} hght={50} />
            </Link>
          )}
        </div>
      )}
      <div className={`flex flex-col mb-2 ${mobile ? `w-5/6` : `w-full`}`}>
        <div className="flex items-center w-auto ">
          <input
            type="text"
            value={text}
            onChange={handleText}
            onKeyUp={handleEnter}
            style={{
              border: `1px solid ${colors.secondary}`,
              outline: `none`,
              color: colors.secondary
            }}
            className={` font-bold w-full px-4 rounded-full py-1 ${
              mobile ? `text-sm` : `text-base`
            }`}
          />
          {text !== `` && (
            <button
              onClick={() => {
                setText(``)
                aceptedCookies && localStorage.setItem(LAST_SEARCH, ``)
                setChips([])
              }}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <IoMdClose size={20} />
            </button>
          )}
          {!mobile && (
            <button
              onClick={handleCloseSearchModal}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <IoIosArrowUp size={24} />
            </button>
          )}
        </div>
        <BoxChips
          mobile={mobile}
          desk={!mobile}
          width={mobile ? 100 : 80}
          {...{
            chips,
            open: true,
            handleClear,
            handleReplace,
            handleReplaceAll,
            colors,
          }}
        />
      </div>
    </>
  )
}

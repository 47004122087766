'use client'
import { OrderFilterSearch } from "@web/_components/filters/global"
/* eslint-disable  no-shadow */

import { ToolsContext } from "@/context/tools/tools"
import { useSearch, useSynonyms } from "@/hooks/search"
import { config } from "@/middleware/config/config"
import { cleanEndString, cleanStartString } from "@/utils/functions"
import { constants } from "@/utils/global"
import { hasCookie } from "cookies-next"
import { useSearchParams } from "next/navigation"
import {
  useCallback,
  useContext,
  useEffect, useMemo, useState
} from "react"
import { useDebounce } from "use-debounce"
import PaginationTW from "../../../../../../components/tailwind/PaginationTW"
import { TopSearch } from "./TopSearch"
import { Catalogue } from "./components"

const {
  COOKIES: { LAST_SEARCH, COOKIES_POLICY },
} = config

export const SearchData = ({
  ismobile, hostname, open, handleCloseSearchModal, ...props
}) => {
  const { login, mobile } = hostname
  const pageParams = useSearchParams()
  const { ci: clientId } = pageParams
  const { list, handleSearchInit, labels, load } = useSearch()
  const currency = {
    exchange: 1,
    symbol: `€`
  }

  const { tools: { colors, texts }} = useContext(ToolsContext)
  const globalTexts = useMemo(() => texts.generic, [texts])
  const { synonyms } = useSynonyms({ hostname })
  const [ value, setValue ] = useState(``)
  const [ text, setText ] = useState(``)
  const [ page, setPage ] = useState(1)
  const [ chips, setChips ] = useState([])
  const [ totalPage, setTotalPage ] = useState(1)
  const [ order, setOrder ] = useState()
  const [ status, setStatus ] = useState(false)
  const aceptedCookies = useMemo(() => hasCookie(COOKIES_POLICY), [])

  const [debouncedText] = useDebounce(text, 350)

  const handleText = e => {
    aceptedCookies && localStorage.setItem(LAST_SEARCH, e.target.value)
    setText(e.target.value)
  }
  const handleInsert = useCallback(itemVal => {
    setText(x => `${x} ${itemVal}`)
  })

  const checkJoinInSynonyms = async arr => {
    const joinedArr = arr.join(` `)
    const nwchps = []
    const nf = []
    const fnd = []
    let foundJoined = false

    arr.forEach((item, indx) => {
      if (foundJoined) {
        return
      }
      const foundSynonym = synonyms.some((syn, i) => {
        const syns = syn.synonyms?.split(`,`)
        if (syn.name.toLowerCase() === joinedArr.toLowerCase() || syns.includes(joinedArr.toLowerCase())) {
          if (!nwchps.some(x => x.label === syn.name)) {
            nwchps.push({
              idx: null,
              label: syn.name,
              referer: joinedArr,
              save: true
            })
          }
          foundJoined = true
          fnd.push(joinedArr)
          return true
        }
        if (syn.name.toLowerCase() === item.toLowerCase() || syns.includes(item.toLowerCase())) {
          // check if the synonym is already in the chips if not add it
          if (!nwchps.some(x => x.label === syn.name)) {
            nwchps.push({
              idx: null,
              label: syn.name,
              referer: item,
              save: true
            })
          }
          fnd.push(item)
          return true
        }
        return false
      })
      if (!foundSynonym) {
        nf.push(item)
      }
    })
    if (nf?.length > 0) {
      const match = await nf.filter(x => x !== ``).map((x, i) => ({
        idx: i,
        label: x,
        referer: x,
        save: true
      }))
      nwchps.push(...match)
    }
    setChips(nwchps)
  }

  const handleSynonyms = useCallback(async(items, flag = false, arr = []) => {
    !flag && await checkJoinInSynonyms(items)

    // setText(x => localStorage.getItem(LAST_SEARCH) || ``)
  }, [synonyms])

  const updateList = async(from = 0) => {
    const newChips = chips?.map(chip => chip.label).join(`+`)
    await handleSearchInit({
      platformId: hostname?.id,
      languageId: hostname?.langId,
      from,
      words: cleanStartString(cleanEndString(value.replace(` `, `+`), `+`), `+`),
      search: cleanStartString(cleanEndString(newChips.replace(` `, `+`), `+`), `+`),
      order: order?.childId,
      clientId: !clientId ? login?.user?.clientId || hostname?.clientId : clientId,
      userId: hostname?.user_id,
      web: true,
      abbrElastic: hostname?.business?.abbrElastic,
      mobile
    })
    aceptedCookies && await localStorage.setItem(LAST_SEARCH, text)
  }

  const handlePage = async page => {
    await setPage(page)
    await setStatus(false)
    await updateList()
  }

  useEffect(() => {
    setValue(debouncedText)
  }, [debouncedText])

  useEffect(() => {
    console.info(``)
    open && aceptedCookies && setText(localStorage.getItem(LAST_SEARCH) || ``)
  }, [open])

  useEffect(() => {
    list.count > 0 && setTotalPage(Math.ceil(list.count / constants.SEARCH_LIMIT))
  }, [list])
  useEffect(() => {
    updateList(page - 1)
  }, [page])

  useEffect(() => {
    if ((!!order || chips?.length) && (chips?.length || !!order)) {
      setStatus(x => true)
    }
  }, [ order, chips ])

  useEffect(() => {
    status && handlePage(1)
  }, [status])

  return <>
    <div
      id="search-div"
      className=""
      style={{
        padding: !mobile ? 10 : 2,
        alignContent: `baseline`,
        alignItems: `flex-start`,
        height: `auto`
      }}>
      <TopSearch {...{
        hostname,
        ismobile: mobile,
        texts,
        text,
        setText,
        handleText,
        handleSynonyms,
        value,
        chips,
        setChips,
        handleCloseSearchModal
      }} />
      <div size={{
        xs: 12,
        md: 12
      }} >
        <div
          style={{
            display: `flex`,
            flexDirection: `row`,
            alignItems: `center`,
            justifyContent: `space-between`,
            marginRight: mobile ? `0` : `48px`,
            marginLeft: mobile ? `8px` : `18px`,
          }}
        >
          <span
            style={{
              padding: `0px 8px`,
              backgroundColor: `#e0e0e0`,
              borderRadius: `16px`,
              fontSize: `14px`,
              height: `26px`,
              lineHeight: `26px`,
            }}
          >
            {`${list.count || 0} ${globalTexts?.results}`}
          </span>
          <OrderFilterSearch
            {...props}
            {...{
              setOrder,
              texts,
              hostname,
            }}
            filters={order ? [order] : []}
          />
        </div>
        <Catalogue {...props} {...{
          // fields: {
          //   platform: hostname,
          //   hostname,
          //   login
          // },
          hostname,
          currency,
          texts,
          relateds: list.data,
          origin: `search`,
          setPages: setPage,
          totalPage,
          page,
          loading: load,
          mobile,
          labels
        }} />

        <div id={`stack-div`} style={{ marginTop: 1 }} className="">
          {totalPage > 1 && <PaginationTW id="pag-div"
            siblingCount={1} boundaryCount={1}
            size="small"
            onChange={(e, nxPag) => setPage(nxPag)} {...{
              count: totalPage,
              page
            }}
            style={{
              display: `inline-flex`,
              justifyContent: `center`
            }} />}
        </div>
      </div>
    </div>
  </>
}


'use client'

import { ToastifyContext } from "@/context"
import { useContext, useEffect, useMemo } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
export const Toastify = props => {
  const {
    show, error, msg, info, rmvErrorToastify
  } = useContext(ToastifyContext)
  const options = useMemo(() => ({
    position: `bottom-right`,
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: `colored`,
  }),
  [])
  useEffect(() => {
    if (show) {
      !error ? info ? toast.info(msg, options) : toast.success(msg, options) : toast.error(msg, options)
      rmvErrorToastify()
    }
  }, [msg])
  return <ToastContainer newestOnTop={true} pauseOnFocusLoss
    rtl={false} pauseOnHover
    {...options} style={{
      fontSize: `14px`,
      fontWeight: 500,
    }} />
}

import { ReactSVG } from "react-svg"
export const SvgNew = ({ id = ``, name, path, click, fillcolor, size = `20px`, wdth, hght }) => (
  <ReactSVG
    id={id}
    beforeInjection={svg => {
    //   svg.classList.add(`svg-class-${name}`)
      // if (fillcolor) {
      //   svg.setAttribute(`style`, `fill: ${fillcolor} !important, width: ${wdth ?? size} !important, height: ${hght ?? size} !important`)
      // }
    }}
    src={`${path}`}
    wrapper="div"
    useRequestCache={true}
    onClick={click}
    className=""

  />
)

export default SvgNew

'use client'

import { useContext } from 'react'
import { SearchData } from '../search'
import { SearchContext } from '@/context/search'

const SearchModal = ({ ...props }) => {
  const { open, toggleSearchClose, mobile } = useContext(SearchContext)

  return (
    <>
      <div onClick={toggleSearchClose}
        className={`fixed inset-0  bg-black transition-opacity duration-300 ${
          open ? `opacity-50` : `opacity-0 pointer-events-none`
        }`}
        style={{
          zIndex: 1560
        }}
      ></div>

      <div
        className={`fixed inset-0 z-[9999] transition-transform duration-300 ease-in-out ${
          open ? `translate-y-0` : `-translate-y-full`
        } bg-white`}
        style={{
          maxHeight: mobile ? `78dvh` : `79dvh`,
          minHeight: mobile ? `78dvh` : `79dvh`,
          width: `100vw`,
          overflow: `hidden`,
        }}
      >

        <div>
          <SearchData {...props} {...{ open, handleCloseSearchModal: toggleSearchClose }} />
        </div>
      </div>
    </>
  )
}

export default SearchModal

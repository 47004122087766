'use client'
import { DropDownArrows } from "@/app/(web)/_components/basket/subcomponent/components/subcomponents/DropDownArrows"
import { handleKeyRandom } from "@/utils/functions"
import {
  ClickAwayListener,
  Grow,
  Popper
} from "@mui/material"
import parse from "html-react-parser"
import Link from "next/link"
import {
  useId, useRef, useState
} from "react"

export const Lang = ({
  infoLang, hostname, colors, ...props
}) => {
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const { isNotBot } = hostname
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return !!hostname?.business?.webUrl.includes(`eurobikes`) && (
    <div>
      <div
        className="text-neutral-600 font-medium hover:text-black text-sm capitalize flex items-center justify-center w-full cursor-pointer"
        ref={anchorRef}
        aria-controls={open ? `split-button-menu` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="select-web-domain"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        {parse(infoLang?.find(lang => lang.abbr_code === hostname?.lang)?.name || ``)}
        <DropDownArrows {...{ open }} />
      </div>
      <Popper open={open} anchorEl={anchorRef.current} transition={true}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
            }}
          >
            <div style={{ color: colors.color }} className="z-50 bg-white rounded-md p-1 text-sm cursor-pointer ">
              <ClickAwayListener onClickAway={handleClose}>
                <div id={idRef} className="overflow-x-hidden overflow-y-auto ">
                  {infoLang?.map((lang, i) => (
                    <Link prefetch={isNotBot} aria-label="web-domain-item" key={handleKeyRandom(idRef, i)} href={lang.url} passHref legacyBehavior scroll={true}>
                      <p className={` hover:bg-neutral-100 py-1 px-2 rounded`}>
                        {parse(lang.name)}
                      </p>
                    </Link>
                  ))}
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default Lang
